import React  from 'react';
import Accordion from 'react-bootstrap/Accordion';


function AccordionFromData(props) {


  return (
    <Accordion defaultActiveKey="0" className="accordion-flush">
      <Accordion.Item eventKey="{props.id}">
        <Accordion.Header>{props.header}</Accordion.Header>
        <Accordion.Body>
          <h4>{props.subheader}</h4>
            <ul>
            {Object.values(props.body).map((e,index) => {
                    return <li key={index}>{e}</li>
                  })
                }
            </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordionFromData;
import React, { useState, useEffect, useRef } from 'react'

import DOTS from 'vanta/dist/vanta.dots.min'
// Make sure window.THREE is defined, e.g. by including three.min.js in the document head using a <script> tag



const BackgroundComponent = (props) => {
    const [vantaEffect, setVantaEffect] = useState(null)
    const myRef = useRef(null)
    useEffect(() => {
      if (!vantaEffect) {
        setVantaEffect(DOTS({
          el: myRef.current, 
          color: 0x52A9FF,
          color2: 0x52A9FF,
          backgroundColor: 0xffffff,
          scale: 1.00,
          scaleMobile: 2.00
        }))
      }
      return () => {
        if (vantaEffect) vantaEffect.destroy()
      }
    }, [vantaEffect])
    return <div id="container" ref={myRef}>

    </div>
  }

  export default BackgroundComponent;
import BackgroundComponent from "../components/backgroundGLOBE";
import AccordionFromData from "../components/AccordionFromData";
import data_startup1 from "../data/data_startup_acc1";
import data_startup2 from "../data/data_startup_acc2";
import data_startup3 from "../data/data_startup_acc3";
import data_startup_stages from "../data/data_startup_stages";
import { Link } from "react-router-dom";

const accordionCreator = (item)=> {
  return <AccordionFromData key={item.id} header={item.header} subheader={item.subheader} body={item.body} item={item.body.children}/>
}


const Startup = () => {
  return (
    <>

    <div className="page startup">
    <BackgroundComponent/>
      <div className="row">

      <div className="col-12 col-md-3 sidePanel">

          <div className="svg startupImage logoImage "></div>
          <h1>Start Ups</h1>


          <div className="navPanel">
            {/* <p className="preCta">We can help you navigate the digital landscape</p> */}
            {/* <ul>
              <li><a href="#intro">Introduction</a></li>
              <li><a href="#stages">Stages</a></li>
              <li><a href="#viability">Viability</a></li>
              <li><a href="#analysis">Technology Review</a></li>
              <li><a href="#general">Project Evaluation</a></li>

            </ul> */}

          </div>
        </div>

        <div className="col-12 col-md-8  offset-md-1 pagePanel">


          <div className="pagePanel--section">
          <div className="svg stage makeStartUp"></div>
            <h2 id="intro">Preparing for success</h2>
            <h3 id="stages">By leveraging experience</h3>
            <p className="preAccordion">Having worked with many start ups over the years, we have gained a unique overview and insight into how we can help Founders avoid many pitfalls and issues they may experience.</p>


            <p className="preAccordion">At any stage of your business development processes and progress can be reviewed and measured.</p>
            {data_startup_stages.map(accordionCreator)}
          </div>

          <div className="pagePanel--section">
            <div className="svg mvpImage makeStartUp"></div>
            <h3 id="viability">Viability</h3>
            <p  className="preAccordion">Before you embark on developing an MVP, you should first challenge and validate your assumptions.</p>
            {data_startup1.map(accordionCreator)}
          </div>
          <div className="pagePanel--section">
            <div className="svg auditImage makeStartUp"></div>
            <h3 id="analysis">Technology Review</h3>
            <p className="preAccordion">Maximise your resources, we can help you determine the best technology to achieve your goals, in both the short and long term.</p>
            {data_startup2.map(accordionCreator)}
          </div>
          <div className="pagePanel--section">
            <div className="svg review makeStartUp"></div>
            <h3 id="general">Project evaluation</h3>
            <p className="preAccordion">Scope would be startup specific, looking at non technical risks along with a technical audit</p>
            {data_startup3.map(accordionCreator)}
          </div>
          <p className="cta"><Link to="/contact">Get in touch</Link></p>


        </div>



      </div>
    </div>
    </>
  );
};

export default Startup;
import React, { useState, useEffect, useRef } from 'react'

import NET from 'vanta/dist/vanta.net.min'
// Make sure window.THREE is defined, e.g. by including three.min.js in the document head using a <script> tag



const BackgroundComponent = (props) => {
    const [vantaEffect, setVantaEffect] = useState(null)
    const myRef = useRef(null)
    useEffect(() => {
      if (!vantaEffect) {
        setVantaEffect(NET({
          el: myRef.current,  color: 0x0dcaf0,
          backgroundColor: 0xffffff,  scale: 1.00,
          scaleMobile: 1.00
        }))
      }
      return () => {
        if (vantaEffect) vantaEffect.destroy()
      }
    }, [vantaEffect])
    return <div id="container" ref={myRef}>
      
    </div>
  }

  export default BackgroundComponent;
import React, { useState, useEffect, useRef } from 'react'

import RINGS from 'vanta/dist/vanta.rings.min'
// Make sure window.THREE is defined, e.g. by including three.min.js in the document head using a <script> tag



const BackgroundComponent = (props) => {
    const [vantaEffect, setVantaEffect] = useState(null)
    const myRef = useRef(null)
    useEffect(() => {
      if (!vantaEffect) {
        setVantaEffect(RINGS({
          el: myRef.current,backgroundColor: 0xffffff,
          color: 0x92c362,  scale: 1.00,
          scaleMobile: 1.00
        }))
      }
      return () => {
        if (vantaEffect) vantaEffect.destroy()
      }
    }, [vantaEffect])
    return <div id="container" ref={myRef}>
      
    </div>
  }

  export default BackgroundComponent;
import BackgroundComponent from "../components/backgroundRINGS";
import AccordionFromData from "../components/AccordionFromData";

import data_tech from "../data/data_agency";
import data_audit from "../data/data_startup_acc3";
import { Link } from "react-router-dom";

const accordionCreator = (item)=> {
  return <AccordionFromData key={item.id} header={item.header} subheader={item.subheader} body={item.body} item={item.body.children}/>
}


const Agency = () => {
  return (
    <>
    <BackgroundComponent/>
    <div className="page agency">
      <div className="row">

      <div className="col-12 col-md-3 sidePanel">

          <div className="svg agencyImage makeAgency logoImage"></div>

          <h1>Agency</h1>
          <div className="navPanel">
            {/* <ul>
              <li><a href="#analysis">Special Projects</a></li>
              <li><a href="#general">General Audit</a></li>
            </ul> */}

          </div>
        </div>

        <div className="col-12 col-md-8  offset-md-1 pagePanel">
          <div className="pagePanel--section">
            <h2 id="intro">Solutions for Agency challenges</h2>
            <div className="svg generation makeAgency"></div>
            <h3 id="analysis">Special Projects</h3>
            <p className="preAccordion">Every week there is a new must have technology released, some maybe useful to your clients, and some not. Overcreative can help you explore these areas and identify opportunities and business use cases.</p>
            {data_tech.map(accordionCreator)}
          </div>
          <div className="pagePanel--section">
            <div className="svg auditImage makeAgency"></div>
            <p className="preAccordion">Established agencies may be outsourcing to 3rd parties or hiring in contractors. This can result in an incoherent and disparate codebase, and require multple skillsets to maintain.</p>
            <h3 id="general">General Audit</h3>
            <p className="preAccordion">Scope would be Agency specific, looking at non technical risks along with a technical audit</p>
            {data_audit.map(accordionCreator)}
          </div>
          <p className="cta"><Link to="/contact">Get in touch</Link></p>
        </div>
      </div>
    </div>
    </>
  );
};

export default Agency;

import BackgroundComponent from "../components/backgroundWAVES";

const Credits = () => {
  return (
    <>
      <BackgroundComponent/>
    <div className="page credits">
       <h1>Credits</h1>
       <h2>Icons</h2>
       <p>start up by jokokerto from <a href="https://thenounproject.com/browse/icons/term/start-up/" rel="noreferrer" target="_blank" title="start up Icons">Noun Project</a></p>
       <p>creative by Made x Made from <a href="https://thenounproject.com/browse/icons/term/creative/" rel="noreferrer" target="_blank" title="creative Icons">Noun Project</a></p>
       <p>Audit by Slamet Ariyanto from <a href="https://thenounproject.com/browse/icons/term/audit/" rel="noreferrer" target="_blank" title="Audit Icons">Noun Project</a></p>
       <p>sme by mynamepong from <a href="https://thenounproject.com/browse/icons/term/sme/" rel="noreferrer" target="_blank" title="sme Icons">Noun Project</a></p>
       <p>contact by Icons Producer from <a href="https://thenounproject.com/browse/icons/term/contact/"  rel="noreferrer" target="_blank" title="contact Icons">Noun Project</a></p>
       <p>Machine Learning by Agni from <a href="https://thenounproject.com/browse/icons/term/machine-learning/" rel="noreferrer" target="_blank" title="Machine Learning Icons">Noun Project</a></p>
       <p>devices by Atif Arshad from <a href="https://thenounproject.com/browse/icons/term/devices/"  rel="noreferrer" target="_blank" title="devices Icons">Noun Project</a></p>
       <p>generation by Aneeque Ahmed from <a href="https://thenounproject.com/browse/icons/term/generation/"   rel="noreferrer" target="_blank" title="generation Icons">Noun Project</a></p>
       <p>Cloud by diavd from <a href="https://thenounproject.com/browse/icons/term/cloud/"  rel="noreferrer"  target="_blank" title="Cloud Icons">Noun Project</a></p>
       <p>autonomous by Gregor Cresnar from <a href="https://thenounproject.com/browse/icons/term/autonomous/"  rel="noreferrer" target="_blank" title="autonomous Icons">Noun Project</a></p>
       <p>challenge by Rusmaniah from <a href="https://thenounproject.com/browse/icons/term/challenge/"  rel="noreferrer" target="_blank" title="challenge Icons">Noun Project</a></p>
       <p>exampleexample ,instance ,lesson ,case, precedent  by WEBTECHOPS LLP from <a href="https://thenounproject.com/browse/icons/term/exampleexample-instance-lesson-case-precedent-doub/"  rel="noreferrer" target="_blank" title="exampleexample ,instance ,lesson ,case, precedent  Icons">Noun Project</a></p>
       <p>stage by Nithinan Tatah from <a href="https://thenounproject.com/browse/icons/term/stage/"  rel="noreferrer" target="_blank" title="stage Icons">Noun Project</a></p>
       <p>review by Chanut is Industries from <a href="https://thenounproject.com/browse/icons/term/review/"  rel="noreferrer"  target="_blank" title="review Icons">Noun Project</a></p>
       <p><a rel="noreferrer"  target="_blank" href="https://icons8.com/icon/39848/unity">Unity</a> icon by <a rel="noreferrer"  target="_blank" href="https://icons8.com">Icons8</a></p>
       <p><a  rel="noreferrer"  target="_blank" href="https://icons8.com/icon/n3QRpDA7KZ7P/tensorflow">Tensorflow</a> icon by <a rel="noreferrer"  target="_blank" href="https://icons8.com">Icons8</a></p>
       <p><a rel="noreferrer"  target="_blank" href="https://icons8.com/icon/l75OEUJkPAk4/python">Python</a> icon by <a rel="noreferrer"  target="_blank" href="https://icons8.com">Icons8</a></p>
       <p><a rel="noreferrer"  target="_blank" href="https://icons8.com/icon/bpip0gGiBLT1/opencv">OpenCV</a> icon by <a rel="noreferrer"  target="_blank" href="https://icons8.com">Icons8</a></p>
       <p><a rel="noreferrer"  target="_blank" href="https://icons8.com/icon/39856/php-logo">PHP Logo</a> icon by <a rel="noreferrer"  target="_blank" href="https://icons8.com">Icons8</a></p>
       <p><a rel="noreferrer"  target="_blank" href="https://icons8.com/icon/106036/javascript-logo">JavaScript Logo</a> icon by <a rel="noreferrer"  target="_blank" href="https://icons8.com">Icons8</a></p>
       <p><a rel="noreferrer"  target="_blank" href="https://icons8.com/icon/35989/react-native">React Native</a> icon by <a  rel="noreferrer" target="_blank" href="https://icons8.com">Icons8</a></p>
       <p><a rel="noreferrer"  target="_blank" href="https://icons8.com/icon/55205/c-sharp-logo">C Sharp Logo</a> icon by <a rel="noreferrer"  target="_blank" href="https://icons8.com">Icons8</a></p>
       <p><a rel="noreferrer"  target="_blank" href="https://icons8.com/icon/35116/xamarin">Xamarin</a> icon by <a rel="noreferrer"  target="_blank" href="https://icons8.com">Icons8</a></p>

    </div>
    </>
  );
};

export default Credits;
import BackgroundComponent from "../components/backgroundRINGS2";
import { Link } from "react-router-dom";
const Solutions = () => {
  return (
    <>

    <div className="page solutions">
    <BackgroundComponent/>
      <div className="row">

        <div className="col-12 col-md-3 sidePanel">

          <div className="svg generation makeProject logoImage"></div>
          <h1>Solutions</h1>


          <div className="navPanel">


          </div>
        </div>

        <div className="col-12 col-md-8 offset-md-1 pagePanel">
        <div className="logoImage makeProject creative"></div>

          <h2>For Marketing and PR</h2>
          <p className="preCta">Campaign Sites, Product Launches, Event Sites</p>
          <p>Overcreative specialises in building and managing everything you need to get you or your clients product or brand online.</p>
          <h2>...and Advertising Agencies</h2>
          <p className="preCta">Agency Sites, Portfolios and beyond</p>
          <p>With over two decades experience working with ATL/BTL agencies we understand the unique challenges that can arise.</p>
          <br></br>
          <br></br>
          <p className="preCta">Knowledge boost</p>
          <p>Integrate analytics with ML analysis to better understand your audience as well as bespoke content publishing tools.</p>
          <br></br>
          <br></br>
          <p className="preCta">Brand Experiences & Special Projects</p>
          <p>Rapid development of visually stunning online or offline digital assets*, that can integrate with your existing tech.**</p>

          <p className="cta"><Link to="/projects">Learn more</Link></p>
          <br></br>
          <br></br>
          <h2>How do we achieve this?</h2>
          <div className="logoImage makeProject example floatLeft"></div>
          <p className="preCta">Content driven project management</p>
          <p>Content is the only reason your site exists. Achieve your goals; through content auditing, curation, and content development.***</p>
          <div className="logoImage makeProject cloud"></div>
          <br></br>
          <br></br>

          <p className="preCta">Data driven decision making</p>
          <p>Match your sites capabilites and content to what your audience actually want. Analytics data paints a picture of what the audience look at, for how long, how they interact, and what device they are doing it on.</p>
          <p>Developing a site in the context of this knowledge gives you the strongest framework.</p>
          <br></br>
          <br></br>
          <p className="preCta">Compliance, Security and Accessibility </p>
          <p>Let us worry about the details, so that you don't have to. We will advise of any risk, and appropriate mitigation.</p>

          <br></br>
          <br></br>
          <p className="cta"><Link to="/contact">Discuss further</Link></p>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <p>* Digital Assets such as Websites, Web Applications, Mobile Apps, Games or IOT to run on PC/MAC iOS or Android</p>
          <p>** Tech such as any CMS or CRM you can think of - we've worked with them all.</p>
          <p>*** Our network includes award winning writers, SEO experts, photographers, artists, designers and seasoned copy writers.</p>

        </div>
      </div>
    </div>
    </>
  );
};

export default Solutions;


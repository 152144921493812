import BackgroundComponent from "../components/backgroundDOTS2";
import csharp from '../logo/c#.png';
import xam from '../logo/xamarin.png';
import opencv from '../logo/opencv.png';
import unity from '../logo/unity.png';
import natml from '../logo/natml.png';
import python from '../logo/python.png';
import pytorch from '../logo/pytorch.png';
import TF from '../logo/TF.png';
import colab from '../logo/colab.png';
import js from '../logo/js.png';
import reactlogo from '../logo/react.png';
import webgl from '../logo/webgl.png';

const Tech = () => {
    return (
      <>
      <BackgroundComponent/>
      <div className="page">
      <div className="row">

      <div className="col-12 col-md-3 sidePanel">
          <div className="svg autonomous makeBusiness logoImage"></div>

          <h1>The Tech</h1>



        </div>

        <div className="col-12 col-md-8  offset-md-1 pagePanel">
          <div className="pagePanel--section tech">
            <div className="techelement">
                <img src={csharp} alt="C#"/>
                <p>C#</p>
            </div>
            <div className="techelement">
                <img src={xam} alt="Xamarin/Maui"/>
                <p>Xamarin/Maui</p>
            </div>
            <div className="techelement">
                <img src={opencv} alt="OpenCV"/>
                <p>OpenCV</p>
            </div>
            <div className="techelement">
                <img src={unity} alt="Unity"/>
                <p>Unity</p>
            </div>
            <div className="techelement">
                <img src={natml} alt="NatML"/>
                <p>NatML</p>
            </div>

            <div className="techelement">
                <img src={python} alt="Python"/>
                <p>Python</p>
            </div>
            <div className="techelement">
                <img src={pytorch} alt="pytorch"/>
                <p>Pytorch</p>
            </div>

            <div className="techelement">
                <img src={TF} alt="Tensorflow"/>
                <p>Tensorflow</p>
            </div>

            
            <div className="techelement">
                <img src={colab} alt="Colab"/>
                <p>Google Colab</p>
            </div>

            <div className="techelement">
            </div>

            <div className="techelement">
                <img src={js} alt="JavaScript"/>
                <p>JavaScript</p>
            </div>

            <div className="techelement">
                <img src={reactlogo} alt="React"/>
                <p>React</p>
            </div>

            <div className="techelement">
                <img src={webgl} alt="WebGL"/>
                <p>WebGL</p>
            </div>




          </div>
        </div>



      </div>
    </div>
      </>
    );
  };
  
  export default Tech;
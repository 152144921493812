import React  from 'react';
// import TopNav  from '../components/TopNav';
import BottomNav from "../components/BottomNav";
import {  Outlet,  useLocation } from "react-router-dom";
import { motion } from "framer-motion"

const HomePageLayout = () => {

  const pathname = useLocation();
  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };
  const PageLayout = ({ children }) => children;

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.5
  };
  return (
    <>
    <PageLayout>
    <motion.div
    key={pathname}
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}
    transition={pageTransition}
    >
    <div className="container">
      <div className="row">
 <div className="col-12 homeTint">
      {/* <TopNav/> */}
      </div> 
      </div>
      <div className="row">
        <div className="col-12">

              <Outlet />

        </div>
      </div>
      <div className="row">
        <div className="col-12 homeTint">
        <BottomNav/>
        </div>
      </div>
    </div>
    </motion.div>
    </PageLayout>
    </>
  )
};


export default HomePageLayout;

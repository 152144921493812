//start  up accordions
const data=[
    {
        id:1,
        header:"Nascent technology and FOMO",
        subheader: "",
        body: {
            item1: "AR/XR - It's possible to rapidly create prototypes or MVP in this area now, such as realtime virtual try on, or other augmented branding experiences",
            item2: "AI/ML - Whether you want to leverage automated content generation, acquisition or explore other ML technology",
            item3: "VR/Metaverse and Games - again this technology is now developed to a point where we can create a prototype in weeks"
        }
    }
];
export default data;
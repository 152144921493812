//start  up accordions
const data=[
    {
        id:1,
        header:"Do I need a technology review?",
        subheader: "Why you should be technology agnostic when you begin your start up journey",
        body: {
            item1: "Technology changes rapidly and what is popular now may not be popular in the future.",
            item2: "You can be open to new technologies as they emerge and adapt your business accordingly. ",
            item3: "Gives you a competitive advantage as you are not tied down to any one particular technology platform.",
            item4: "Allows you to focus on your core business goals and not get distracted by the latest shiny tech gadget."
        }
    }
];
export default data;
import BackgroundComponent from "../components/backgroundDOTS2";
import email from "../logo/info@overcreative.com.png";
const Contact = () => {
    return (
    <>
    <BackgroundComponent/>
      <div className="page contact">
        <div className="row">
          <div className="col-12 col-md-3 sidePanel">
            <div className="svg contactImage logoImage makeWhite"></div>
            <h1>Contact</h1>

          </div>
          <div className="col-12 col-md-8  offset-md-1 pagePanel">
            <div className="pagePanel--section">
              <h2>Email</h2>
              <p className="large-text">Drop us a line if you would like to discuss anything in more detail.</p>
              <h3><a href="mailto:info@overcreative.com"><img src={email} alt="info@overcreative.com"></img></a></h3>
            </div>
          </div>
        </div>

      </div>

    </>);
  };

  export default Contact;
import { Link } from "react-router-dom";
import BackgroundComponent from "../components/backgroundWAVES";
const Home = () => {
    return (
      <>

<div className="page home">
      <BackgroundComponent/>
      <div className="row">

      {/* <div className="col-12 col-md-3 sidePanel">


        </div> */}

        <div className="col-12 col-md-8 offset-md-2 pagePanel">


        <div className="pagePanel--section">
        <div className="svg oc-logo-black  logoImage"></div>
          <h1>OVERCREATIVE</h1>
            <h2>Development</h2>
            <div className="homeNav">
              <nav>
                <ul className="multi">
                <li>
                    <Link to="/solutions">
                    <div className="logoImage makeHome generation "></div>
                    <h3>Digital Solutions</h3>
                    </Link>
                  </li>
                  <li>
                    <Link to="/projects">
                    <div className="logoImage makeHome machineLearning "></div>
                    <h3>Special Projects</h3>
                    </Link>
                  </li>
      
                </ul>
              </nav>
            </div>
          </div>
 

          <div className="pagePanel--section">
            <h2>Consultancy</h2>
            <p>We specialise in helping our clients from all sectors achieve their digital goals. </p>
            <div className="homeNav">
              <nav>
                <ul className="multi">
                <li>
                    <Link to="/startup">
                    <div className="logoImage makeHome startupImage"></div>
                    <h3>Start Ups</h3>

                    </Link>
                  </li>
                  <li>
                    <Link to="/agency">

                    <div className="logoImage makeHome agencyImage"></div>
                    <h3>Agency</h3>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/business">
                    <div className="logoImage makeHome smeImage"></div>
                    <h2>SME</h2>
                    </Link>
                  </li> */}
 
                </ul>
              </nav>
            </div>
          </div>



        </div>



      </div>
    </div>
    </>
    );
  };

  export default Home;
//start  up accordions
const data=[
    {
        id:1,
        header:"What's involved?",
        subheader: "Examples:",
        body: {
            item1: "Bugs vs Features.",
            item2: "GDPR/CCPA: Are you truly compliant?",
            item3: "ESG risks: Are your servers actually net zero?",
            item4: "Process optimisation: release ordering/project sequencing.",
            item5: "Failure to prepare for success: Fulfilment risk.",
            item6: "Beyond MVP: Future roadmap planning.",
            item7: "IP threat: 3rd party audits, does your time saving plug in expose you to risks.",
            item8: "Collateral IP generation: sometimes you invent things by accident.",
            item9: "Data driven decisions: are you getting all the metrics you need.",
            item10: "Legacy creep/drain: Sometimes the past comes back to haunt you."
            }
        }

];
export default data;



import BackgroundComponent from "../components/backgroundNET";
import { Link } from "react-router-dom";
const Projects = () => {
  return (
    <>

    <div className="page projects">
    <BackgroundComponent/>
      <div className="row">

        <div className="col-12 col-md-3 sidePanel">

          <div className="svg machineLearning makeProject logoImage"></div>
          <h1>Projects</h1>


          <div className="navPanel">


          </div>
        </div>

        <div className="col-12 col-md-8 offset-md-1 pagePanel">
          <h1>What we make</h1>
          <p className="preCta">Custom tools, apps or applications.</p>
          <p>Overcreative specialises in building everything from start up prototypes/MVP to native mobile apps, web apps, bespoke CMS, bespoke tools for businesses, or highly performant and visually stunning websites.</p>



          <br/><br/><br/>
          <h2 id="intro">Case Studies:</h2>
          <div className="logoImage makeProject generation"></div>


            <ul>
            <li className="caseStudy"><div className="svgBullet example"></div><span className="caseStudyTitle">Bespoke Tools</span>Automated and realtime Ad scheduling system for 118 118</li>
          <li className="caseStudy"><div className="svgBullet example"></div><span className="caseStudyTitle">Bespoke CMS</span>Bespoke Website/CMS system specifically designed for car dealers</li>

              <p className="preCta">Can you train an AI to recognise truth?</p>
              <p>In the news media within the post truth age, 'facts' and 'authority' are constant sources of contention. Is there a way to train an AI or use various techniques to attempt to validate the 'truth' of a news article?</p>
              <p>Combining functions that identify journalists techniques, along with a combination of AI techniques to appraise content, you can start to build an idea of something that can at least attempt to score an article on its 'authenticity', if not its 'truthfulness'. This project is ongoing.</p>


              <li className="caseStudy"><div className="svgBullet example"></div><span className="caseStudyTitle">Web App</span> Overcreative built an AI powered news/copy authentication & appraisal system for a PR disrupting start up</li>

              <li className="preCta">Advantages of autonomous text generation</li>
              <li>Speed; review or generate reams of copy almost instantly</li>
              <li>Compliance checks can be built in</li>
              <li>Content filters can be added</li>
              <li>House styles can be applied</li>
              <li className="caseStudy"><div className="svgBullet example"></div><span className="caseStudyTitle">!!!</span>This site is using AI generated content!</li>

              <li className="preCta">Advantages of autonomous text extraction</li>
              <li>Speed and accuracy</li>
              <li>Combine other text analysis at the same time</li>
              <li className="caseStudy"><div className="svgBullet example"></div>
              <span className="caseStudyTitle">Web App/SAAS</span>Overcreative built an AI powered SAAS application for an Australian Law Firm and even integrated it with their Wordpress site</li>
            </ul>



          <div className="logoImage makeProject device"></div>

            <ul>
              <li className="preCta">On Device Detection</li>
              <li>On edge adaptable custom object detection systems.</li>
              <li>For realtime tracking e.g.@ on lower end devices, drones etc</li>
              <br/>
              <li className="preCta">Advantages over using cloud solutions</li>
              <li>Zero server costs</li>
              <li>Security</li>
              <li>Runs on device without needing internet connection</li>
              <li className="caseStudy">
                <div className="svgBullet example"></div>
                <span className="caseStudyTitle">Mobile App</span>Overcreative built a prototype and MVP app for an entertainment startup that uses our on-device detection system to detect specific pet species that runs on Android and iOS devices.</li>
              <p className="cta"><Link to="/contact">Let’s Build Your Idea</Link></p>

            </ul>

        </div>
      </div>
    </div>
    </>
  );
};

export default Projects;


import { Link } from "react-router-dom";

const BottomNav = () => {
    return (
        <>
            <nav className="bottomNav">
                <ul className="nav nav-pills">
                    <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
                    <li className="nav-item"><Link to="/projects" className="nav-link">Solutions</Link></li>
                    <li className="nav-item"><Link to="/projects" className="nav-link">Projects</Link></li>
                    <li className="nav-item"><Link to="/agency" className="nav-link">Agency</Link></li>
                    <li className="nav-item"><Link to="/startup" className="nav-link">Start Ups</Link></li>
                    <li className="nav-item"><Link to="/contact" className="nav-link">Contact</Link></li>
                    <li className="nav-item"><Link to="/past" className="nav-link">Past</Link></li>
                    <li className="nav-item"><Link to="/tech" className="nav-link">Tech</Link></li>
                    <li className="nav-item"><Link to="/credits" className="nav-link">Credits</Link></li>
                    <li className="nav-item"><Link to="/privacy" className="nav-link">Privacy</Link></li>

                </ul>
            </nav>
        </>
        )
        };

export default BottomNav;




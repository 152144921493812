import React  from 'react';
import TopNav from "../components/TopNav";
import BottomNav from "../components/BottomNav";
import {  Outlet,  useLocation } from "react-router-dom";
import { motion } from "framer-motion"

const StandardLayout = () => {

  const pathname = useLocation();
  const pageVariants = {
    initial: {
      opacity: 0,
      // x:"100%"
    },
    in: {
      opacity: 1,
      // x:"0%"
    },
    out: {
      opacity: 0,
      // x:"-100%"
    }
  };
  const PageLayout = ({ children }) => children;

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.5
  };
  return (
    <>
    <PageLayout>
    <motion.div
    key={pathname}
    initial="initial"
    animate="in"
    variants={pageVariants}
    transition={pageTransition}
    >
    <div className="container">
      <div className="row">
        <div className="col-12">
        <TopNav/>
        </div>
      </div>
      <div className="row">
        <div className="col-12">

              <Outlet />

        </div>
      </div>
      <div className="row">
        <div className="col-12">
        <BottomNav/>
        </div>
      </div>
    </div>
    </motion.div>
    </PageLayout>
    </>
  )
};


export default StandardLayout;

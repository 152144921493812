//start  up accordions
const data=[
    {
        id:1,
        header: "When can we help?",
        subheader:"Example Stage related tests, reviews or benchmarks",
        body: {
            item1: "Concept - Viability",
            item2: "Preproduction - Seamless user experiences, and concept refinement",
            item3: "MVP - IRL performance reviews, Device reach, Temporal metrics",
            item4: "Soft launch - User feedback qualification, user behaviour pattern mapping, privacy stress testing.",
            item5: "Pre launch  - Stress testing, social media cohesion with on-device native notifications, ad systems approval advice.",
            item6: "Post launch - Monitor install metrics vs marketing, crash logs analytics, pre OS update upgrade planning.",
        }
    }
];
export default data;
//start  up accordions
const data=[
    {
        id:1,
        header: "Find out more",
        subheader:"There are a few key reasons why start-ups should undertake technical viability review: ",
        body: {
            item1: "Validate that the concept is actually possible",
            item2: "Assess whether the necessary infrastructure and resources are in place to support the product or service.",
            item3: "Gain a better understanding of the potential market for the product or service, and to identify any gaps or opportunities.",
            item4: "Ensure that the product or service can be delivered as intended, and that any potential risks have been identified and mitigated.",
            item5: "Identify any areas of key innovation, that can generate new IP or enable your business to receive R&D funding or grants you may not have been aware of."
        }
    }
];
export default data;